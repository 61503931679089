import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ServiceService} from '../../services/service.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  signinForm: any;
  error = null;
  email : any = true;
  password : any = false;
  partner : any;
 
  constructor(private spinner: NgxSpinnerService,private call : ServiceService,private jwtAuth: JwtAuthService,private router: Router,private activateRoute: ActivatedRoute) {
    
   }

  ngOnInit(): void {
    this.spinner.show();
    this.activateRoute.queryParams.subscribe(params =>{
     
      let username = '';
      if(params.recover){ // ESTO EN CASO DE QUE VENGAMOS DE RECUPERAR LA CONTRASE*A
        username = params.recover;
        this.email = false;
        this.password = true;
      }
      this.signinForm = new FormGroup({
        username: new FormControl(username, [Validators.required,Validators.email]),
        password: new FormControl('', Validators.required)
      });

      this.call.getService(Number(localStorage.getItem("service"))).subscribe(response =>{
          this.spinner.hide();
          this.partner = response.partner_id;
      }, err =>{
        this.spinner.hide();
        console.log(err);
      });

    });

    this.spinner.hide();
    
  }

  findEmail(){
    
    this.spinner.show();
    // BUSCAMOS EL EMAIL
    this.call.getAuth(this.signinForm.value.username, this.partner).subscribe(response =>{
      // AQUI LO ENCONTRO ENTONCES MOSTRAMOS PARA INTRODUCIR EL PASSWORD
      this.password = true;
      this.email = false;
      this.spinner.hide();

    }, err =>{
      this.spinner.hide();
      // SI ENTRA POR AQUI ES QUE NO LO ENCONTRO REGISTRADO POR LO QUE MOSTRAMOS PANTALLA DE REGISTRO
  
      this.router.navigateByUrl("/" + localStorage.getItem("service") + "/register");

    })
    
  }

  goBack(){
    this.password = false;
    this.email = true;
  }

  signin() {
    this.spinner.show();
    const signinData = this.signinForm.value;
    this.jwtAuth.signin(signinData.username, signinData.password, this.partner)
    .subscribe(response => {
     

        let data : any = localStorage.getItem('currentData');
        if(data == null){
          this.signin();
        }else{
          this.activateRoute.queryParams.subscribe(queryParams=>{
            this.spinner.hide();
            // ESTE PASO LO HACEMOS PARA COMPROBAR SI EL USUARIO ESTA INICIANDO SESION ENVIADO DESDE LA PANTALLA DE CHECKOUT
            // AQUI LO QUE HACEMOS ES SI PODEMOS INICIAR SESION SIN PROBLEMAS ENTONCES ENVIAMOS AL USUARIO DIRECTO AL CHECKOUT
            // CON LA INFORMACION CARGADA
            if(queryParams.subscription != undefined){
              let data : any = localStorage.getItem("checkout");
              this.router.navigate(["/" + localStorage.getItem("service") + "/checkout"], {relativeTo: this.activateRoute, queryParamsHandling: 'preserve'});
            }else{
              // enviamos al perfil normal del usuario
              this.router.navigate(['/'], {relativeTo: this.activateRoute});
            }
          })
           
       }
    
     
    }, err => {
      console.log("ERROR")
     
      this.spinner.hide();
      console.log(err);
      this.error = err;
  
    })
  }

  recoverPass(){
    this.router.navigate(['../recover'], {relativeTo: this.activateRoute, queryParamsHandling: 'preserve'});
  }


}
