import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse} from '@angular/common/http';
import { BaseService } from './base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  public HOST: string = environment.backendUrl;
  private readonly BASE_URL: string = this.HOST + '/ecommerce';
  private readonly BASE_AUTH: string = this.HOST + '/auth/';
  httpOptions : any;


  constructor(private http: HttpClient,private baseService : BaseService) {
    this.httpOptions = {
         
    }
   }

    private subjectName = new Subject<any>(); //need to create a subject
    private subjectName2 = new Subject<any>(); //need to create a subject
    
    sendUpdate(message: string) { //the component that wants to update something, calls this fn
            this.subjectName.next({ text: message }); //next() will feed the value in Subject
    }

    sendUpdate2(message: string) { //the component that wants to update something, calls this fn
      this.subjectName2.next({ text: message }); //next() will feed the value in Subject
}
    
    getUpdate(): Observable<any> { //the receiver component calls this function 
            return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
    }


    getUpdate2(): Observable<any> { //the receiver component calls this function 
      return this.subjectName2.asObservable(); //it returns as an observable to which the receiver funtion will subscribe

    } 

    getConfig(key : any){
      return this.http.get(this.baseService.HOST + "/config/key/" + key , this.httpOptions)
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    getService(id : number,params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.BASE_URL + "/service/" + id , {})
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    getPlans(id:number, params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.BASE_URL + "/plan/service/" + id, {})
      .pipe(
        catchError(this.baseService.handleError)
      );
    }

    GetProvinces(): Observable<any> {
      return this.http.get(this.HOST + "/province" , {})
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }
  
    getOptions(id:number, params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.BASE_URL + "/option/plan/" + id, {})
      .pipe(
        catchError(this.baseService.handleError)
      );
    }

    getAuth(email:string, partner : number): Observable<any> {
      return this.http.get(this.BASE_AUTH  + email + "/" + partner , {})
      .pipe(
        catchError(this.baseService.handleError)
      );
    }

    recoverPassword(email:string, service:number): Observable<any> {
      return this.http.get(this.BASE_AUTH +'recover/' + email + "/" + service, {})
      .pipe(
        catchError(this.baseService.handleError)
      );
    }
  
    getPlan(id : number,params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.BASE_URL + "/plan/" + id , {})
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }
  
    getOption(id : number,params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.BASE_URL + "/option/" + id , {})
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    registryCustomer(customer: any): Observable<any> {
      return this.http.post(this.BASE_URL + "/customer/registry", customer, this.httpOptions)
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    verifyToken(token: any): Observable<any> {
      return this.http.post(this.HOST + "/campaingToken/verify", token, this.httpOptions)
      .pipe(
        map(this.baseService.extractData)
      );
    }

    resendToken(token: any): Observable<any> {
      return this.http.post(this.HOST + "/campaingToken/resend", token, this.httpOptions)
      .pipe(
        map(this.baseService.extractData)
      );
    }

    update(customer: any): Observable<any> {
      return this.http.put(this.BASE_URL + '/customer/' + customer.id, customer,this.httpOptions)
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    findByCustomerInCorer(IDcustomer:number) : Observable<any>{
      return this.http.get(this.baseService.HOST + '/customer/' + IDcustomer + '/creditcard',this.httpOptions)
        .pipe(
          catchError(this.baseService.handleError)
        );
    }

    findByCustomer(IDcustomer:number,params: HttpParams = new HttpParams()) : Observable<any>{
      this.httpOptions.params = params;
      return this.http.get(this.baseService.HOST +  '/creditcard' + '/customer/' + IDcustomer , this.httpOptions)
        .pipe(
          catchError(this.baseService.handleError)
        );
    }


    findByToken(IDcustomer:number,token: string) : Observable<any>{
      return this.http.get(this.baseService.HOST + '/customer/' + IDcustomer + '/creditcard/token/' + token,this.httpOptions)
      .pipe(
        catchError(this.baseService.handleError)
      );
    }

    findTdcById(IDcustomer:number,tdc: number) : Observable<any>{
      return this.http.get(this.baseService.HOST + '/customer/' + IDcustomer + '/customerHasCreditCard/' + tdc,this.httpOptions)
      .pipe(
        catchError(this.baseService.handleError)
      );
    }

    create(IDcustomer:number,cc : any): Observable<Object[]> {
      /*
       {	"numberCreditCard" : "1287128444",
       "expMonthCreditCard" : "02",
       "expYearCreditCard" : "17",
       "nameHolderCreditCard" : "Juan Perez"
       }
       */

      return this.http.post(this.baseService.HOST + '/customer/' + IDcustomer + '/creditcard', {"numberCreditCard" : cc.numberCreditCard,
      "expMonthCreditCard" : cc.expMonthCreditCard.toLocaleString(), "expYearCreditCard" : cc.expYearCreditCard.toLocaleString(),
      "nameHolderCreditCard" : cc.nameHolderCreditCard}, this.httpOptions)
        .pipe(
          map(this.baseService.extractData),
          catchError(this.baseService.handleError)
        );
    }
    
    changePassword(data: any, id:any): Observable<any> {
      return this.http.post(this.BASE_URL + "/users/" + id + "/changePassword", data, this.httpOptions)
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }
    // /adress/customer/:id
    getAddresses(id : number,params: HttpParams = new HttpParams()): Observable<any> {
      this.httpOptions.params = params;
      return this.http.get(this.baseService.HOST + "/adress/customer/" + id , this.httpOptions)
      .pipe(
        catchError(this.baseService.handleError)
      );
    }

    createAdress(adress: any): Observable<any> {
      return this.http.post(this.baseService.HOST + "/adress", adress, this.httpOptions)
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    post(object: any, url: string): Observable<any> {
      return this.http.post(url, object, this.httpOptions)
        .pipe(
          map(this.baseService.extractData),
          catchError(this.baseService.handleError)
        );
    }

    updateAdress(adress: any): Observable<any> {
      return this.http.put(this.baseService.HOST + "/adress/" + adress.id, adress, this.httpOptions)
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    deleteAdress(id: any): Observable<any> {
      return this.http.delete(this.baseService.HOST + "/adress/" + id, {})
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }
    
    getCustomer(id : number,params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.BASE_URL + "/customer/" + id , {})
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }
      // this.baseService.HOST + '/customer/' + IDcustomer + '/customerHasCreditCard/' + td
    deleteTdc(IDcustomer:number,id: number, json : any): Observable<any> {
   
      return this.http.post(this.baseService.HOST + '/customer/' + IDcustomer + '/creditcard/' + id + '/delete',json, this.httpOptions)
      .pipe(
        catchError(this.baseService.handleError)
      );
    }

    deleteToken(url: any): Observable<any> {
      return this.http.delete(url, {})
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    // /ecommerce/partner/:idpartner/branch/:idbranch/service/:idservice/plan/:idplan/suscribe

    sendSucribe(object: any): Observable<any> {
      return this.http.post(this.HOST + "/suscription", object, this.httpOptions)
      .pipe(
        catchError(this.baseService.handleError)
      );
    }

    // sendSucribe(object: any, service:any,plan:any): Observable<any> {
    //   return this.http.post(this.BASE_URL + "/service/" + service + "/plan/" + plan + "/suscribe", object, this.httpOptions)
    //   .pipe(
    //     catchError(this.baseService.handleError)
    //   );
    // }

    getHasPlans(customer : any,params: HttpParams = new HttpParams()): Observable<any> {
      this.httpOptions.params = params;
      return this.http.get(this.baseService.HOST + "/hasplan/customer/" + customer , this.httpOptions)
      .pipe(
        catchError(this.baseService.handleError)
      );
    }

    getHasPlan(chp : any,params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.baseService.HOST + "/hasplan/" + chp , {})
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    getCC(cc : any,params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.baseService.HOST + "/creditcard/" + cc , {})
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }
    
    getAdress(id : number,params: HttpParams = new HttpParams()): Observable<any> {
      this.httpOptions.params = params;
      return this.http.get(this.HOST + "/adress/" + id , this.httpOptions)
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    updateShippingAdress(data: any): Observable<any> {
      return this.http.put(this.BASE_URL + "/adress/" + data.hasPlan, data, this.httpOptions)
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    updatePayment(data: any): Observable<any> {
      return this.http.post(this.HOST + "/creditcard/payment/change", data, this.httpOptions)
      .pipe(
        catchError(this.baseService.handleError)
      );
    }

    cancel(id: any): Observable<any> {
      return this.http.put(this.BASE_URL + "/hasplan/" + id +"/cancel", {}, this.httpOptions)
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    pause(id: any): Observable<any> {
      return this.http.put(this.BASE_URL + "/hasplan/" + id +"/pause", {}, this.httpOptions)
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    activate(id: any): Observable<any> {
      return this.http.put(this.BASE_URL + "/hasplan/" + id +"/activate", {}, this.httpOptions)
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }
  
    getLastConfiguration(id : number,params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.BASE_URL+ "/configuration/customer/" + id , {})
      .pipe(
        map(this.baseService.extractData),
        catchError(this.baseService.handleError)
      );
    }

    get(url:string,params: HttpParams = new HttpParams()):Observable<any>{
      this.httpOptions.params = params;
    return this.http.get(url, this.httpOptions)
    .pipe(
      catchError(this.baseService.handleError)
    );
  }


  suscribe(object: any, url: string): Observable<any> {
    return this.http.post(url, object, this.httpOptions)
      .pipe(
        catchError(this.baseService.handleError)
      );
  }
}
