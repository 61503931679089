
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ServiceService} from '../../services/service.service';

@Component({
  selector: 'app-fac-popup',
  templateUrl: './fac-popup.component.html',
  styleUrls: ['./fac-popup.component.scss']
})
export class FacPopupComponent implements OnInit {

  responseForm : any;

  constructor(
    private call : ServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FacPopupComponent>
  ) { }

  ngOnInit(): void {
    console.log("AQUI");
    console.log(this.data);
    this.responseForm = this.data.form
  }

  exit() {
    if(this.data.id != null){
      this.call.getCC(this.data.id).subscribe(cc =>{

        if(cc.verified){
          this.dialogRef.close();
        }
        
      }, err =>{
        this.dialogRef.close();
      })
    }else{
      console.log("CONSULTANDO: " + this.call.HOST + "/creditcard/" + this.data.creditCardId)
      this.call.get(this.call.HOST + "/creditcard/" + this.data.creditCardId).subscribe ( creditcard =>{
        console.log("RESPUESTA: " , creditcard)
        if(creditcard.result.status == 1){
          this.dialogRef.close(creditcard.result.verified);
        }
      }, err =>{
        console.error(err); // LA TARJETA FUE ELIMINADA NO SE ENCONTRO
        this.dialogRef.close(false);
      });
    }}

}
